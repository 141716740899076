import '../styles/globals.scss';
import './../styles/NavBar.scss';

import React, { useEffect } from 'react';
import { AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import { GA_MEASUREMENT_ID, pageView } from '../lib/gtagManager';

declare global {
    const googletag: {
        cmd: {
            push: (fn: () => void) => void;
        };
        apiReady: boolean;
        sizeMapping: () => any;
        pubads: () => {
            addEventListener: (event: string, listener: (event: any) => void) => void;
            getSlots: () => any[];
            refresh: (args?: any) => void;
            enableSingleRequest: () => any;
            collapseEmptyDivs: () => any;
            setCentering: (args: boolean) => any;
        };
        defineSlot: (adUnitPath: string, size: any, divId: string) => any;
        enableServices: () => void;
        display: (args: string) => void;
        destroySlots: (args?: any) => void;
        pubadsReady: boolean;
    };

    interface Window {
        gtag: (key: string, trackingId: string, config: any) => void;
        adsbygoogle: {
            push: (args: any) => void;
        };
        jwplayer: (elmId: string) => {
            setup: (config: { aspectratio: string; playlist: string }) => void;
        };

        sprtlTargeting: {
            tag?: string[];
            category?: string[];
            article?: string[];
            section?: string[];
            author?: string[];
        };

        googletag: {
            sizeMapping: () => any;
            pubads: () => {
                addEventListener: (event: string, listener: (event: any) => void) => void;
                getSlots: () => any[];
                refresh: (args?: any) => void;
                enableSingleRequest: () => any;
                collapseEmptyDivs: () => any;
                setCentering: (args: boolean) => any;
                setTargeting: (key: string, value: string | string[]) => void;
            };
            cmd: {
                push: (fn: () => void) => void;
            };
        };
    }
}

const App = ({ Component, pageProps }: AppProps) => {
    const AnyComponent: any = Component;
    useEffect(() => {
        //@ts-ignore
        import('bootstrap/dist/js/bootstrap.bundle.min');
        pageView(window.location.pathname);
    }, []);

    return (
        <>
            {/* Load top priority scripts before any Next.js code and before any page hydration occurs.*/}
            {/*OneTrust Cookies Consent Notice start for woman.bg*/}
            <Script
                src='https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
                type='text/javascript'
                strategy={'beforeInteractive'}
                data-language='bg'
                charSet='UTF-8'
                data-domain-script='9729b924-e227-4d8a-a8ab-39171ac875a4'
            />
            <Script
                strategy='beforeInteractive'
                src='https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4137371004109827'
            />
            <Script
                strategy={'beforeInteractive'}
                src={`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`}
                className='optanon-category-C0002'
            />
            <Head>
                {/*OneTrust Cookies Consent Notice start for woman.bg*/}
                <script
                    id='consent-system'
                    type='text/javascript'
                    dangerouslySetInnerHTML={{ __html: `function OptanonWrapper() { }` }}></script>
                <link rel='icon' href='/favicon.png' sizes='any' />
                <link rel='manifest' href='/manifest.json' />
                <meta name='theme-color' content='#fadce4' />
                <meta name='description' content='Woman.bg - за истинската жена' />
                <script id='script-component-ad' src='https://securepubads.g.doubleclick.net/tag/js/gpt.js' />
                <script
                    className='optanon-category-C0002'
                    dangerouslySetInnerHTML={{
                        __html: `window.dataLayer = window.dataLayer || [];
                         function gtag(){dataLayer.push(arguments);}
                         gtag('js', new Date());
                         gtag('config', '${GA_MEASUREMENT_ID}');`,
                    }}
                />
                <script
                    className='optanon-category-C0002'
                    dangerouslySetInnerHTML={{
                        __html: `var pp_gemius_identifier = 'nGiaeEwHd7WRaot7Y4c4N8Rpj6MKHycCU_qQ3Ioxjjn.R7';
                            // lines below shouldn't be edited
                            function gemius_pending(i) { window[i] = window[i] || function() {var x = window[i+'_pdata'] = window[i+'_pdata'] || []; x[x.length]=arguments;};};
                            gemius_pending('gemius_hit'); gemius_pending('gemius_event'); gemius_pending('gemius_init');
                            gemius_pending('pp_gemius_hit'); gemius_pending('pp_gemius_event');
                            gemius_pending('pp_gemius_init');
                            (function(d,t) {try {var gt=d.createElement(t),s=d.getElementsByTagName(t)[0],l='http'+((location.protocol=='https:')?'s':'');
                            gt.setAttribute('async','async'); gt.setAttribute('defer','defer');
                            gt.src=l+'://gabg.hit.gemius.pl/xgemius.js';
                            s.parentNode.insertBefore(gt,s);} catch (e) {}})(document,'script');
                         `,
                    }}
                />
                <link rel='preconnect' href='https://fonts.googleapis.com' />
                <link rel='preconnect' href='https://fonts.gstatic.com' />
                <link
                    href='https://fonts.googleapis.com/css2?family=Lato:wght@700&family=Playfair+Display:wght@700&display=swap'
                    rel='stylesheet'
                />
                <meta property='fb:admins' content={`${process.env.NEXT_PUBLIC_SOCIAL_FB_ADMIN_ID}`} />
                <meta property='fb:app_id' content={`${process.env.NEXT_PUBLIC_SOCIAL_FB_APP_ID}`} />
                <meta property='fb:pages' content={`${process.env.NEXT_PUBLIC_SOCIAL_FB_PAGE_ID}`} />
            </Head>
            <div id='fb-root' />
            <AnyComponent {...pageProps} />
        </>
    );
};

export default App;
